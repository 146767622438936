'use client';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import {
  motion,
  useMotionValueEvent,
  useScroll,
  useTransform,
} from 'framer-motion';
import {
  FC,
  ReactNode,
  useRef,
  useState,
} from 'react';
import useMeasure from 'react-use-measure';

interface Props {
  className?: string;
  children?: ReactNode;
}

export const AnimatedItem: FC<Props> = ({
  className,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [wrapperMeasureRef, wrapperMeasure] =
    useMeasure();
  const placeholderRef =
    useRef<HTMLDivElement | null>(null);

  const [isFixed, setIsFixed] = useState(false);

  const { scrollYProgress } = useScroll({
    target: ref,
    axis: 'y',
    offset: ['start end', 'end'],
  });

  useMotionValueEvent(
    scrollYProgress,
    'change',
    (latestValue) => {
      setIsFixed(latestValue === 1);
    }
  );

  const {
    scrollYProgress: scrollAfterYProgress,
  } = useScroll({
    target: ref,
    axis: 'y',
    offset: ['end', '90%'],
  });

  const opacity = useTransform(
    scrollAfterYProgress,
    [1, 1],
    [1, 1]
  );

  const scale = useTransform(
    scrollAfterYProgress,
    [0, 1],
    ['100%', '70%']
  );

  return (
    <div
      ref={ref}
      className={twMergeExt(cvaRoot(), className)}
    >
      <motion.div
        ref={wrapperMeasureRef}
        className={cvaItemWrapper({ isFixed })}
        style={{
          opacity,
          scale,
          translateX: isFixed ? '-50%' : 0,
        }}
      >
        {children}
      </motion.div>

      {isFixed ? (
        <div
          ref={placeholderRef}
          className={cvaPlaceholder()}
          style={{
            height: wrapperMeasure.height,
          }}
        />
      ) : null}
    </div>
  );
};

const cvaRoot = cva([
  'AnimatedItem-cvaRoot',
  ' [&:last-child_.AnimatedItem-cvaItemWrapper]:!opacity-100',
  ' [&:last-child_.AnimatedItem-cvaItemWrapper]:!scale-100',
  ' [&:last-child_.AnimatedItem-cvaItemWrapper.fixed]:!-translate-x-1/2',
]);

const cvaItemWrapper = cva(
  [
    'AnimatedItem-cvaItemWrapper',
    'bottom-0',
    'w-full',
  ],
  {
    variants: {
      isFixed: {
        true: 'fixed bottom-0 left-1/2',
        false: 'relative',
      },
    },
  }
);

const cvaPlaceholder = cva([
  'AnimatedItem-cvaPlaceholder',
]);
