import { API } from '../api';
import { axiosInstance } from '../axios';
import { IBlog } from '../getBlog/types';

export const getBlogByProduct = async (
  slug: string,
  page: number
) => {
  try {
    const result = await axiosInstance.get<IBlog>(
      API.getBlogByProduct(slug, page)
    );
    return result.data?.data;
  } catch (e: any) {
    console.error(e);
  }
};
