'use client';
import { ArticlePreviewCard } from '@/entities/blog';
import { getBlog } from '@/shared/api/getBlog';
import {
  IBlogData,
  IPost,
} from '@/shared/api/getBlog/types';
import { getBlogByProduct } from '@/shared/api/getBlogByProduct';
import { Button } from '@/shared/ui/Button';
import { Container } from '@/shared/ui/Container';
import { Link } from '@/shared/ui/Link';
import { SliderProgress } from '@/shared/ui/SliderProgress';
import { Title } from '@/shared/ui/Title';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { useInfiniteQuery } from '@tanstack/react-query';
import { cva } from 'class-variance-authority';
import { useInView } from 'framer-motion';
import { FC, useRef, useState } from 'react';
import { Autoplay } from 'swiper/modules';
import {
  Swiper,
  SwiperSlide,
} from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';
import ArrowRightIcon from '~/icons/arrow-right.svg';
import PlusIcon from '~/icons/plus.svg';

interface Props {
  className?: string;
  initialData: IBlogData;
  slug?: string;
}

export const BlogPreviewSection: FC<Props> = ({
  className,
  initialData,
  slug,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const inView = useInView(ref, { once: true });
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['blog'],
    initialPageParam: 1,
    initialData: initialData
      ? {
          pageParams: [1],
          pages: [initialData],
        }
      : undefined,
    queryFn: ({ pageParam = 1 }) => {
      return slug
        ? getBlogByProduct(slug, pageParam)
        : getBlog(pageParam);
    },
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.page_data.total_pages !==
        lastPage?.page_data.page
        ? allPages.length + 1
        : undefined;
    },
  });

  const [swiper, setSwiper] =
    useState<SwiperType | null>(null);

  return (
    <Container
      ref={ref}
      className={twMergeExt(cvaRoot(), className)}
      variant={'full'}
      inViewAnimation={true}
    >
      <Container variant={'large'}>
        <Container
          className={cvaTopContainer()}
          variant={'small'}
        >
          <Title
            as={'h2'}
            trigger={inView}
            className={cvaHeading()}
          >
            Экспертный блог
          </Title>
          <Link
            className={cvaAllArticlesLink()}
            contentWrapperClassName={
              'flex justify-center items-center gap-0.8 pl-0.9'
            }
            color={'lightGrey'}
            size={'medium'}
            hoverEffect={'highlight'}
            prefetch={false}
            href={
              process.env
                .NEXT_PUBLIC_FRONT_BASE_URL +
              '/news/'
            }
          >
            Все статьи
            <ArrowRightIcon
              className={'w-1.6 h-1.6 mt-0.3'}
            />
          </Link>
        </Container>
      </Container>

      <Swiper
        className={twMergeExt(
          cvaArticlesList(),
          'lg:hidden'
        )}
        modules={[Autoplay]}
        autoplay={{ delay: 10000 }}
        slidesPerView={'auto'}
        spaceBetween={12}
        slidesOffsetBefore={32}
        slidesOffsetAfter={32}
        breakpoints={{
          0: {
            spaceBetween: 8,
            slidesOffsetBefore: 24,
            slidesOffsetAfter: 24,
          },
          1024: {
            spaceBetween: 12,
            slidesOffsetBefore: 32,
            slidesOffsetAfter: 32,
          },
        }}
        onSwiper={setSwiper}
      >
        {data?.pages.map((page) =>
          page?.posts.map(
            (i: IPost, index: number) => (
              <SwiperSlide
                key={index}
                className={
                  'w-[86.6%] max-w-[58.5rem] sm:!w-[58.5rem] group'
                }
              >
                <ArticlePreviewCard data={i} />
              </SwiperSlide>
            )
          )
        )}
      </Swiper>

      <div
        className={twMergeExt(
          cvaArticlesList(),
          'hidden lg:flex'
        )}
      >
        {data?.pages.map((page) =>
          page?.posts.map(
            (i: IPost, index: number) => (
              <ArticlePreviewCard
                key={index}
                data={i}
              />
            )
          )
        )}
      </div>

      <Container variant={'large'}>
        <div
          className={'flex flex-col items-center'}
        >
          <SliderProgress
            className={
              'lg:hidden mt-2.4 sm:mt-3.2'
            }
            swiper={swiper}
          />
          {hasNextPage ? (
            <Button
              className={cvaMoreButton()}
              contentWrapperClassName={
                'flex justify-center items-center gap-[0.45rem] pl-0.9'
              }
              color={'outlinedOnWhite'}
              size={'big'}
              hoverEffect={'highlight'}
              onClick={() => fetchNextPage()}
              isLoading={isFetchingNextPage}
            >
              Показать еще
              <PlusIcon
                className={'w-1.8 h-1.8'}
              />
            </Button>
          ) : null}
          <Link
            className={cvaAllArticlesLinkMobile()}
            contentWrapperClassName={
              'flex justify-center items-center gap-0.8 pl-0.9'
            }
            color={'outlinedOnWhite'}
            size={'big'}
            hoverEffect={'highlight'}
            prefetch={false}
            href={
              process.env
                .NEXT_PUBLIC_FRONT_BASE_URL +
              '/news/'
            }
          >
            Все статьи
            <ArrowRightIcon
              className={'w-1.6 h-1.6 mt-0.3'}
            />
          </Link>
        </div>
      </Container>
    </Container>
  );
};

const cvaRoot = cva([
  'BlogPreviewSection-cvaRoot',
  'my-6.4 sm:my-[10.4rem]',
]);

const cvaTopContainer = cva([
  'BlogPreviewSection-cvaTopContainer',
  'flex justify-between items-center',
  'px-0 sm:px-3.2 md:px-4.2 lg:px-5.6',
]);

const cvaHeading = cva([
  'BlogPreviewSection-cvaHeading',
  'font-secondary text-h2',
]);

const cvaAllArticlesLink = cva([
  'BlogPreviewSection-cvaAllArticlesLink',
  'hidden lg:flex items-center gap-0.8',
  'min-h-5.6',
  'px-3.2',
  'text-bodyMSemibold',
]);

const cvaAllArticlesLinkMobile = cva([
  'BlogPreviewSection-cvaAllArticlesLinkMobile',
  'flex lg:hidden items-center gap-0.8',
  'mt-2.4 sm:mt-3.2 lg:mt-4.8 mx-auto',
  'text-bodyLSemibold',
  'text-bodyLSemibold',
]);

const cvaArticlesList = cva([
  'BlogPreviewSection-cvaArticlesList',
  'lg:flex lg:flex-col lg:gap-1.2',
  'mt-2.4 sm:mt-4.8',
]);

const cvaMoreButton = cva([
  'BlogPreviewSection-cvaMoreButton',
  'mt-3.2 lg:mt-4.8 mx-auto',
  'text-bodyLSemibold',
  'hidden lg:block',
]);
