'use client';
import { AnimatedTranslateWrapper } from '@/shared/ui/AnimatedTranslateWrapper';
import { Button } from '@/shared/ui/Button';
import { Link } from '@/shared/ui/Link';
import { SliderProgress } from '@/shared/ui/SliderProgress';
import BusIcon from '@/widgets/about-section/assets/bus.svg';
import CalendarIcon from '@/widgets/about-section/assets/calendar.svg';
import QualityIcon from '@/widgets/about-section/assets/quality.svg';
import { cva } from 'class-variance-authority';
import { FC, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useMedia } from 'react-use';
import { Autoplay } from 'swiper/modules';
import {
  Swiper,
  SwiperSlide,
} from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';
import ArrowRightIcon from '~/icons/arrow-right.svg';

interface Props {
  className?: string;
  variant?: 'col' | 'row';
  trigger: boolean;
}

export const AboutFeatures: FC<Props> = ({
  className,
  variant = 'row',
  trigger,
}) => {
  const [swiper, setSwiper] =
    useState<SwiperType | null>(null);
  const isTablet = useMedia(
    '(min-width: 768px)',
    false
  );

  const { ref: ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div ref={ref} className={className}>
      <AnimatedTranslateWrapper
        initial={{
          y: '8rem',
          opacity: 0,
        }}
        transition={{
          duration: 1,
          ease: 'easeInOut',
        }}
        trigger={inView}
        className={cvaRoot()}
      >
        <Swiper
          modules={[Autoplay]}
          autoplay={{ delay: 10000 }}
          slidesPerView={'auto'}
          breakpoints={{
            0: {
              spaceBetween: 8,
              slidesOffsetAfter: 24,
              slidesOffsetBefore: 24,
            },
            768: {
              spaceBetween: 12,
              slidesOffsetAfter: 24,
              slidesOffsetBefore: 24,
            },
            1024: {
              spaceBetween: 12,
              slidesOffsetAfter: 0,
              slidesOffsetBefore: 0,
            },
            1440: {
              spaceBetween: 12,
              slidesOffsetAfter: 0,
              slidesOffsetBefore: 0,
            },
          }}
          onSwiper={setSwiper}
        >
          <SwiperSlide className={cvaSlide()}>
            <AnimatedTranslateWrapper
              className={cvaFeature({ variant })}
              initial={{ y: '1.2rem' }}
              transition={{ delay: 0.4 }}
              trigger={trigger}
            >
              <AnimatedTranslateWrapper
                className={cvaFeatureInnerWrapper(
                  {
                    variant,
                  }
                )}
                initial={{ y: '2.4rem' }}
                transition={{ delay: 0.4 }}
                trigger={trigger}
              >
                <QualityIcon
                  className={cvaFeatureIcon()}
                />
                <div>
                  <div
                    className={cvaFeatureTitle()}
                  >
                    100% оригинальные
                  </div>
                  <div
                    className={cvaFeatureDescr()}
                  >
                    товары
                  </div>
                </div>
              </AnimatedTranslateWrapper>
              <div className={cvaSeparator()} />
            </AnimatedTranslateWrapper>
          </SwiperSlide>
          <SwiperSlide className={cvaSlide()}>
            <AnimatedTranslateWrapper
              className={cvaFeature({ variant })}
              initial={{ y: '1.2rem' }}
              transition={{ delay: 0.5 }}
              trigger={trigger}
            >
              <AnimatedTranslateWrapper
                className={cvaFeatureInnerWrapper(
                  {
                    variant,
                  }
                )}
                initial={{ y: '2.4rem' }}
                transition={{ delay: 0.5 }}
                trigger={trigger}
              >
                <CalendarIcon
                  className={cvaFeatureIcon()}
                />
                <div>
                  <div
                    className={cvaFeatureTitle()}
                  >
                    Контроль сроков
                  </div>
                  <div
                    className={cvaFeatureDescr()}
                  >
                    годности
                  </div>
                </div>
              </AnimatedTranslateWrapper>
              <div className={cvaSeparator()} />
            </AnimatedTranslateWrapper>
          </SwiperSlide>
          <SwiperSlide className={cvaSlide()}>
            <AnimatedTranslateWrapper
              className={cvaFeature({ variant })}
              initial={{ y: '1.2rem' }}
              transition={{ delay: 0.6 }}
              trigger={trigger}
            >
              <AnimatedTranslateWrapper
                className={cvaFeatureInnerWrapper(
                  {
                    variant,
                  }
                )}
                initial={{ y: '2.4rem' }}
                transition={{ delay: 0.6 }}
                trigger={trigger}
              >
                <BusIcon
                  className={cvaFeatureIcon()}
                />
                <div>
                  <div
                    className={cvaFeatureTitle()}
                  >
                    Доставка за 35-90 мин
                  </div>
                  <div
                    className={cvaFeatureDescr()}
                  >
                    в городах присутствия
                  </div>
                </div>
              </AnimatedTranslateWrapper>
              <div className={cvaSeparator()} />
            </AnimatedTranslateWrapper>
          </SwiperSlide>
          <SwiperSlide className={cvaSlide()}>
            <Link
              prefetch={false}
              href={
                process.env
                  .NEXT_PUBLIC_FRONT_BASE_URL +
                '/about/'
              }
            >
              <AnimatedTranslateWrapper
                className={cvaFeature({
                  variant,
                })}
                initial={{ y: '1.2rem' }}
                transition={{ delay: 0.7 }}
                trigger={trigger}
              >
                <AnimatedTranslateWrapper
                  className={cvaFeatureInnerWrapper(
                    { variant }
                  )}
                  initial={{ y: '2.4rem' }}
                  transition={{ delay: 0.7 }}
                  trigger={trigger}
                >
                  <Button
                    className={cvaFeatureIcon({
                      isLink: true,
                    })}
                    hoverEffect={'highlight'}
                    color={'mediumGreyOnWhite'}
                    size={'small'}
                  >
                    <ArrowRightIcon
                      className={
                        'w-1.6 h-1.6 text-cBlack'
                      }
                    />
                  </Button>
                  <div>
                    <div
                      className={cvaFeatureTitle()}
                    >
                      Подробнее
                    </div>
                    <div
                      className={cvaFeatureDescr()}
                    >
                      о магазине
                    </div>
                  </div>
                </AnimatedTranslateWrapper>
              </AnimatedTranslateWrapper>
            </Link>
          </SwiperSlide>
        </Swiper>

        <SliderProgress
          className={cvaSliderProgress()}
          swiper={swiper}
          forceTotal={isTablet ? 2 : undefined}
        />
      </AnimatedTranslateWrapper>
    </div>
  );
};

const cvaRoot = cva([
  'AboutFeatures-cvaRoot',
  'flex flex-wrap justify-center gap-1.2',
]);

const cvaSlide = cva([
  'AboutFeatures-cvaSlide',
  '!w-min',
]);

const cvaFeature = cva(
  [
    'AboutFeatures-cvaFeature group/AboutFeature',
    'relative',
    'flex',
    'p-2 sm:p-2.8 lg:p-3.2',
    'rounded-[2rem] sm:rounded-[3.2rem]',
    'text-bodyM',
    'bg-cLightGrey',
  ],
  {
    variants: {
      variant: {
        row: 'w-[19.2rem] sm:w-[23rem] lg:w-[30.9rem]',
        col: 'w-[19.2rem] sm:w-[23rem] lg:w-[25.1rem]',
      },
    },
  }
);

const cvaFeatureInnerWrapper = cva(
  [
    'AboutFeatures-cvaFeatureInnerWrapper',
    'flex',
    'w-full h-full',
  ],
  {
    variants: {
      variant: {
        row: 'flex-col gap-3.2 lg:flex-row lg:gap-2.4',
        col: 'flex-col gap-3.2 sm:gap-4.8',
      },
    },
  }
);

const cvaFeatureIcon = cva(
  [
    'AboutFeatures-cvaFeatureIcon',
    'flex justify-center items-center',
    'w-4 h-4 sm:w-4.8 sm:h-4.8',
  ],
  {
    variants: {
      isLink: {
        true: [
          'bg-cDarkGreyOnGrey',
          'group-hover/AboutFeature:before:translate-y-0 group-hover/AboutFeature:before:scale-[1.2] group-hover/AboutFeature:before:transition-transform group-hover/AboutFeature:before:duration-400 group-hover/AboutFeature:before:ease-button-before',
          'group-hover/AboutFeature:after:translate-y-0 group-hover/AboutFeature:after:[transition-duration:0.05s] group-hover/AboutFeature:after:[transition-delay:0.4s] group-hover/AboutFeature:after:transition-transform group-hover/AboutFeature:after:ease-button-after',
        ],
        false: '',
      },
    },
  }
);

const cvaFeatureTitle = cva([
  'AboutFeatures-cvaFeatureTitle',
]);

const cvaFeatureDescr = cva([
  'AboutFeatures-cvaFeatureDescr',
  'text-cBlack48',
]);

const cvaSeparator = cva([
  'BonusProgramSection-cvaSeparator',
  'absolute top-1/2 -right-0.8 -translate-y-1/2 sm:-right-1.2',
  'w-0.8 h-full sm:w-1.2',
  'bg-cLightGrey',
  'before:absolute before:top-0 before:left-0 before:bg-cWhite before:w-full before:h-[calc(50%-0.8rem)] before:rounded-b-[2rem] sm:before:h-[calc(50%-1.2rem)] sm:before:rounded-b-[3.2rem]',
  'after:absolute after:bottom-0 after:left-0 after:bg-cWhite after:w-full after:h-[calc(50%-0.8rem)] after:rounded-t-[2rem] sm:after:h-[calc(50%-1.2rem)] sm:after:rounded-t-[3.2rem]',
]);

const cvaSliderProgress = cva([
  'AboutFeatures-cvaSliderProgress',
  'flex md:hidden',
  'mt-2.4',
]);
