import { REVALIDATE } from '@/shared/constants';
import { API } from '../api';
import { axiosInstance } from '../axios';
import { IBlog } from './types';

export const getBlog = async (page: number) => {
  try {
    const result = await axiosInstance.get<IBlog>(
      API.getBlog(page),
      {
        cache: { ttl: REVALIDATE },
      }
    );
    return result.data?.data;
  } catch (e: any) {
    console.error(e);
  }
};
