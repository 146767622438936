'use client';
import {
  FC,
  ReactNode,
  Children,
  useRef,
} from 'react';
import { cva } from 'class-variance-authority';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { AnimatedItem } from '@/features/catalog-categories-animation/ui/AnimatedItem';
import {
  motion,
  useScroll,
  useTransform,
} from 'framer-motion';

interface Props {
  className?: string;
  children?: ReactNode;
}

export const CatalogCategoriesAnimation: FC<
  Props
> = ({ className, children }) => {
  const ref = useRef<HTMLDivElement>(null);

  const { scrollYProgress } = useScroll({
    target: ref,
    axis: 'y',
    offset: ['start end', 'end'],
  });

  const transform = useTransform(
    scrollYProgress,
    (v) =>
      v === 1 ? 'translate3d(0, 0, 0)' : 'none'
  );

  if (!Children.toArray(children).length) {
    return null;
  }

  return (
    <motion.div
      ref={ref}
      className={twMergeExt(cvaRoot(), className)}
      style={{ transform }}
    >
      <div className={'flex-1'} />
      {Children.map(children, (child) => (
        <AnimatedItem>{child}</AnimatedItem>
      ))}
    </motion.div>
  );
};

const cvaRoot = cva([
  'CatalogCategoriesAnimation-cvaRoot',
  'flex flex-col',
]);
