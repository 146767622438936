import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

export const useExpandedArray = <T>(
  array: Array<T>,
  visibleCount: number
) => {
  const [visibleItems, setVisibleItems] =
    useState(array.slice(0, visibleCount));

  const moreCount = useMemo(() => {
    const diff =
      array.length - visibleItems.length;
    return diff < 0 ? 0 : diff;
  }, [visibleItems.length]);

  const isExpanded = useMemo(
    () => moreCount === 0,
    [moreCount]
  );

  const onExpand = useCallback(() => {
    setVisibleItems(array);
  }, [array]);

  useEffect(() => {
    setVisibleItems(array.slice(0, visibleCount));
  }, [visibleCount]);

  return {
    visibleItems,
    moreCount,
    isExpanded,
    onExpand,
  };
};
